const lng = function() {
    return document.location.pathname.split('/')[1] || 'en';
};

export default [
    {
        id: "acceptOrders",
        name: "Worktime Plan & Fact",
        path: "/operators/worktime-plan-fact"
    },
    {
        id: "crossales",
        name: "Crossales",
        path: "/crossales"
    },
    {
        id: "operatorsActivity",
        name: "Operators Activity",
        path: "/operators/activity-report"
    },
];
