<template>
    <div class="formSwitch">
        <label class="form-check form-switch">
            <input
                class="form-check-input"
                type="checkbox"
                @click="changeSwitch"
                :checked="checked">
            <span
                v-if="label"
                class="formSwitch__label">
                {{ label }}
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'FormSwitch',
    props: {
        label: {
            type: [String, Boolean],
            default: '',
            required: true
        },
        checked: {
            type: Boolean,
            required: true
        }
    },
    emits: [
        'update:checked',
        'updated',
    ],
    data() {
        return {};
    },
    computed: {},
    methods: {
        changeSwitch(event) {
            this.$emit('update:checked', event.target.checked)
            this.$emit('updated')
        }
    },
    beforeMount() {
    }
};
</script>

<style lang="scss">
    @import '../scss/utils/mixins.scss';
    @import '../scss/utils/variables.scss';

    .formSwitch{
        height: $form-control-height;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-right: 40px;
        &__label{
            font-family: $font-secondary;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: $color-day-dark-gray2;
            padding-left: 10px;
            white-space: nowrap;
        }
    }

.form-check {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: $form-control-height;
    padding-left: 1.5rem;
    .form-check-input {
        float: left;
        margin-left: -1.5rem
    }
}

.form-check-input {
    width: 58px;
    height: 30px;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(101,109,119,.24);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    &[type=checkbox] {
        border-radius: 4px;
        &:indeterminate {
            background-color: $color-day-light-green;
            border-color: $color-day-light-green;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
        }
    }
    &:active {
        filter: brightness(90%)
    }
    &:focus {
        border-color: $color-day-line;
        outline: 0;
        box-shadow: none;
    }
    &:checked {
        background-color: $color-day-light-green;
        border-color: $color-day-line;
    }
    &:checked[type=checkbox] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8.5l2.5 2.5l5.5 -5.5'/%3e%3c/svg%3e")
    }
    &:disabled {
        pointer-events: none;
        filter: none;
        opacity: .5
    }
}
.form-switch {
    padding-left: 2.5rem;
    .form-check-input {
        width: 58px;
        margin-left: -2.5rem;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='%23E1E6EB'/%3e%3c/svg%3e");
        background-position: left center;
        border-radius: 2rem;
        transition: background-position .15s ease-in-out
    }
}
@media (prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}
.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='%23ffffff'/%3e%3c/svg%3e")
}
.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}
</style>
