export default {
    namespaced: true,
    state: () => ({
        groups: [],
    }),
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items).forEach(key => {
                state[key] = items[key];
            });
        },
    },
    actions: {
        getGroups(store) {
            return new Promise((resolve => {
                WV.ApiMyspace.getOperatorGroups()
                    .then(response => {
                        store.commit('set', ['groups', response.data]);
                        resolve();
                    });
            }));
        },
    },
    getters: {}
};
