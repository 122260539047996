export default {
    namespaced: true,
    state: () => ({
        goods: [],
        nullGood: {
            good_id: null,
            name: "Any good",
            prices: [],
            images: {
                src_sm: "https://minio.cdnwv.com/lk.webvork/goods/placeholders/sm.png",
                src_md: "https://minio.cdnwv.com/lk.webvork/goods/placeholders/md.png",
                src_lg: "https://minio.cdnwv.com/lk.webvork/goods/placeholders/lg.png"
            }
        },
    }),
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items).forEach(key => {
                state[key] = items[key];
            });
        },
    },
    actions: {
        getGoods(store) {
            return new Promise((resolve => {
                WV.Api.getGoods()
                    .then(response => {
                        store.commit('set', ['goods', response.data]);
                        resolve();
                    });
            }));
        },
    },
    getters: {
        goodsWithAnyGood(state) {
            return [...state.goods, state.nullGood];
        },
        getGoodById: (state, getters) => (good_id) => {
            return collect(getters.goodsWithAnyGood)
                .where('good_id', good_id)
                .first();
        },

    }
};
