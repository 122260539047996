export default {
    namespaced: true,
    state: () => ({
        recommendations: [],
        recommendation: [],
    }),
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items).forEach(key => {
                state[key] = items[key];
            });
        },
    },
    actions: {
        getRecommendations(store) {
            return new Promise((resolve => {
                WV.ApiMyspace.getRecommendations()
                    .then(response => {
                        let validRecommendations = collect(response.data)
                            .map(recommendation => {
                                if (recommendation.main_good_id === '') recommendation.main_good_id = null;
                                return recommendation;
                            })
                            .filter(recommendation => {
                                let issetMainGood = WV.store.getters['goods/getGoodById'](recommendation.main_good_id) !== undefined;
                                let issetCrossGood = WV.store.getters['goods/getGoodById'](recommendation.cross_good_id) !== undefined;

                                return issetMainGood && issetCrossGood;
                            })
                            .all();

                        store.commit('set', ['recommendations', validRecommendations]);
                        resolve();
                    });
            }));
        },
        createRecommendation(store, recommendation) {
            return new Promise((resolve, reject) => {
                WV.ApiMyspace.createRecommendation(recommendation)
                    .then(() => resolve())
                    .catch((error) => {
                        reject(error)
                    });
            });
        },
        updateRecommendation(store) {
            return new Promise((resolve => {
                WV.ApiMyspace.updateRecommendation(store.state.recommendation).then(() => resolve());
            }));
        },
        deleteRecommendation(store) {
            return new Promise((resolve => {
                WV.ApiMyspace.deleteRecommendation(store.state.recommendation).then(() => resolve());
            }));
        },

    },
    getters: {

    }
};
