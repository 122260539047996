import { createStore } from 'vuex';
import menu from './menu.js';
import auth from './moduleAuth.js';
import crossales from './moduleCrossales.js';
import goods from './moduleGoods.js';
import countries from './moduleCountries.js';
import operatorsActivity from './moduleOperatorsActivity.js';
import operatorGroups from './moduleOperatorGroups.js';

export default createStore({
    modules: {
        auth,
        crossales,
        goods,
        countries,
        operatorsActivity,
        operatorGroups,
    },
    state: {
        menu: menu,
    },
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items).forEach(key => {
                state[key] = items[key];
            });
        },
    },
    actions: {},
    getters: {},
});
