<template>
    <footer class="layout__footer">

        <!-- Footer copy -->
        <div class="layout__footer_copy-block">
            <a href="#" class="layout__footer_logo">
                <img src="/images/footer-webvork-logo.svg" alt="Webvork logo"/>
            </a>
            <p class="layout__footer_copy layout__footer_copy-year">
                © Copyright 2017 — {{ (new Date).getFullYear() }}
            </p>
            <p class="layout__footer_copy">
                webwork international ltd
            </p>
            <p class="layout__footer_copy">
                <span>wisteria grange barn, pikes end,</span>
                <span>pinner, england, ha5 2ex</span>
            </p>

            <a
                target="_blank"
                :href="termsOfWorkLink"
                class="layout__footer_terms">
                Terms of work
            </a>
        </div>

        <div class="layout__footer_navigation">
            <!-- Footer menu -->
            <div class="layout__footer_menu-block">

                <h3 class="layout__footer_menu-title">
                    {{ $route.meta.title }}
                </h3>

<!--                <ul class="layout__footer_menu">
                    <li
                        v-for="element in menuElements"
                        class="layout__footer_menu-item">
                        <a
                            :href="getElementPath(element)"
                            @click.prevent="goToRoute(element)"
                            class="layout__footer_menu-link">
                            {{ element.name }}
                        </a>
                    </li>
                </ul>-->

            </div>

        </div>

    </footer>
</template>

<script>
module.exports = {
    name: 'Footer',
    props: [],
    data() {
        return {};
    },
    computed: {
        menuElements() {
            return this.$store.state.menu;
        },
        termsOfWorkLink() {
            return document.location.pathname.split('/')[1] === 'ru'
                ? '/terms/terms-ru.pdf'
                : '/terms/terms-en.pdf'
        },
    },
    methods: {
        getElementPath(element) {
            if (typeof element.path === 'function') {
                return element.path()
            } else {
                return element.path
            }
        },
        goToRoute(element) {
            if (this.getElementPath(element) !== '#') {
                if (element.external) {
                    window.open(this.getElementPath(element), '_blank').focus()
                } else {
                    this.$router.push(element.path)
                }
            }
        },
    },
    beforeMount() {
    }
};
</script>

<style lang="scss">
@import '../../scss/utils/mixins.scss';
@import '../../scss/utils/variables.scss';

.layout__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    margin-top: auto;
    position: relative;
    justify-content: center;
    @include break(md) {
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
    }
    a {
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }
    a:hover {
        color: $color-day-black;
    }
    &_logo {
        margin-bottom: 30px;
    }

    &_copy-block {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-right: auto;
        padding-bottom: 20px;
        @include maxWidth(720) {
            margin-right: 0;
            align-items: center;
        }
    }

    &_copy,
    &_terms {
        margin-bottom: 15px;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $color-day-gray;
        font-family: $font-secondary;
        @include maxWidth(720) {
            text-align: center;
            margin-bottom: 0;
        }

        span {
            display: block;
            text-transform: capitalize;
        }
    }

    &_copy-year {
        @include maxWidth(720) {
            margin-bottom: 15px;
        }
    }

    &_terms {
        display: block;
        margin-top: 40px;
        margin-bottom: 0;
        text-transform: inherit;

        @include maxWidth(720) {
            margin-top: 15px;
        }
    }

    &_navigation {
        height: 100%;
        display: flex;
        flex-direction: column;
        @include break(md) {
            width: calc(100% - 300px);
        }
        @include break(xl) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &_menu {
        width: 100%;
        flex: none;
        display: flex;
        flex-direction: row;
        //flex-flow: column wrap;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        padding-top: 15px;
        padding-bottom: 30px;
        &-block {
            width: 100%;
            display: none;
            @include break(sm) {
                display: flex;
                flex-direction: column;
            }
        }
        &-title {
            height: 40px;
            display: flex;
            width: 100%;
            flex: none;
            justify-content: flex-start;
            align-items: flex-start;
            border-bottom: 1px solid $color-day-line;
            font-family: $font-secondary;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: $color-day-dark-gray;
        }
        &-item {
            width: 50%;
            flex: none;
            height: 30px;
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: $color-day-dark-gray;
            @include break(sm) {
                width: 25%;
            }
            @include break(md) {
                width: 33%;
            }
        }
    }
}
</style>
