export default {
    namespaced: true,
    state: () => ({
        timelineWidth: 7000,
        activities: [],
    }),
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items).forEach(key => {
                state[key] = items[key];
            });
        },
    },
    actions: {
        getOperatorActivities(store, {date, group_id}) {
            return new Promise((resolve => {
                WV.ApiMyspace.getOperatorActivities(date, group_id)
                    .then(response => {
                        store.commit('set', ['activities', response.data]);
                        resolve();
                    });
            }));
        },
    },
    getters: {}
};
