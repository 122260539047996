import '@/scss/main.scss';

/* Sentry */
import * as Sentry from "@sentry/vue";

/* HTTP Client */
import axios from 'axios';

window.axios = axios;

/* EventBus */
import mitt from 'mitt';

/* collect.js */
import collect from 'collect.js';

window.collect = collect;

/* date-fns */
import dateFormat from 'date-fns/format';
import differenceInYears from 'date-fns/differenceInYears';


Date.prototype.addDays = function(days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.getFormattedDate = function () {
    let d = new Date(this.valueOf());
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
};

/* API */
import Api from './Api/Api';
import ApiMyspace from './Api/ApiMyspace';

/* Toast */
import Toastify from 'toastify-js';

import { createApp } from 'vue';
import router from './router';
import store from './store';

/* Register Components */
import App from './App.vue';
import DateRangePicker from './components/DateRangePicker/index';
import DatePicker from '@/components/DatePicker/DatePicker';
import Loading from './components/Loading.vue';
import Alert from './components/Alert.vue';
import SvgUse from './components/SvgUse.vue';
import SortingIcons from './components/SortingIcons.vue';
import FormInput from './components/FormInput.vue';
import FormInputCurrency from './components/FormInputCurrency.vue';
import FormTextarea from './components/FormTextarea.vue';
import FormRadio from './components/FormRadio.vue';
import FormSelect from './components/FormSelect.vue';
import FormSelectMulti from './components/FormSelectMulti.vue';
import FormSwitch from './components/FormSwitch.vue';
import Card from './components/Card.vue';
import Modal from './components/Modal.vue';
import AvatarLetter from './components/AvatarLetter.vue';
import InView from './components/InView.vue';
import ShowMore from './components/ShowMore.vue';

window.WV = {
    validations: require('./libs/validations.js').default,
    collect: require('collect.js'),
    Bus: mitt(),
    getUrlParamByName(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    },
    isAuthRoute() {
        return window.location.pathname.indexOf('/auth/') !== -1
    },
    Date: {
        format: dateFormat,
        differenceInYears: differenceInYears,
        toUTC: (date) => {
            date = new Date(date);
            let utcDate = (new Date);
            utcDate.setFullYear(date.getUTCFullYear());
            utcDate.setMonth(date.getUTCMonth())
            utcDate.setDate(date.getUTCDate());
            utcDate.setHours(date.getUTCHours());
            utcDate.setMinutes(date.getUTCMinutes());
            utcDate.setSeconds(date.getUTCSeconds());
            return utcDate;
        }
    },
    store,
    router,
    toast: {
        success: (message, duration = 3000) => {
            Toastify({
                text: message,
                duration: duration,
                close: false,
                gravity: 'top',
                position: 'right',
                backgroundColor: 'linear-gradient(to right, #0e9472, #44dab3)',
                stopOnFocus: true,
                onClick: () => {
                }
            })
                .showToast();
        },
        error: (message, duration = 3000) => {
            Toastify({
                text: message,
                duration: duration,
                close: true,
                gravity: 'top',
                position: 'right',
                backgroundColor: 'linear-gradient(to right, #ff0100, #c90706)',
                stopOnFocus: true,
                onClick: () => {
                }
            })
                .showToast();
        }
    },
    cookie: {
        get(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        set(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires="+d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }
    },
    throttle: (fn, delay) => {
        let time = Date.now();
        return () => {
            if((time + delay - Date.now()) <= 0) {
                fn();
                time = Date.now();
            }
        }
    },
    debounce(fn, delay = 300){
        let timer;
        return (...args) => {
            clearTimeout(delay);
            timer = setTimeout(() => { fn.apply(this, args); }, delay);
        };
    },
    Api: new Api(),
    ApiMyspace: new ApiMyspace(),
};

WV.app = createApp(App)
    .use(store)
    .use(router)
    .component('AvatarLetter', AvatarLetter)
    .component('Loading', Loading)
    .component('Alert', Alert)
    .component('SvgUse', SvgUse)
    .component('FormInput', FormInput)
    .component('FormInputCurrency', FormInputCurrency)
    .component('FormTextarea', FormTextarea)
    .component('FormRadio', FormRadio)
    .component('FormSelect', FormSelect)
    .component('FormSelectMulti', FormSelectMulti)
    .component('FormSwitch', FormSwitch)
    .component('Card', Card)
    .component('Modal', Modal)
    .component('SortingIcons', SortingIcons)
    .component('DateRangePicker', DateRangePicker)
    .component('DatePicker', DatePicker)
    .component('InView', InView)
    .component('ShowMore', ShowMore);

WV.app.directive('click-outside', {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value();
            }
        }
        document.addEventListener("click", el.clickOutsideEvent)
    },
    unmounted: el => { document.removeEventListener("click", el.clickOutsideEvent) },
})

WV.app.config.globalProperties.$WV = WV;
WV.app.config.globalProperties.$validations = WV.validations;

Sentry.init({
    app: WV.app,
    dsn: "https://13dd5a4d32a5394abc01c7bd67c79c70@sentry.webvork.site/21",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


WV.app.mount('#app');
