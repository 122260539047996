export class BaseApi {

    /**
     * @constructor
     */
    constructor() {

        this.currentDomainZone = document.location.hostname.split('.')[document.location.hostname.split('.').length - 1];

        this.baseUrl = process.env.NODE_ENV === 'production'
            ? 'https://myspace-api.webvork.' + this.currentDomainZone + '/'
            : 'http://localhost:8082/';

        this.config = {
            //withCredentials: true
        };
    }

    /**
     * Prepare request headers
     */
    prepareRequest() {
        this.config.headers = {
            'content-Type': 'application/json',
            "Cache-Control": "no-cache",
            Authorization: `Bearer ${WV.store.getters['auth/getToken']}`,
        };
        axios.defaults.withCredentials = false;
    }

    /**
     * GET request
     * @method get
     * @param {string} endpoint - URL
     */
    get(endpoint) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios.get(this.baseUrl + endpoint, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * POST request
     * @method post
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    post(endpoint, payload) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios.post(this.baseUrl + endpoint, payload, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * PUT request
     * @method put
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    put(endpoint, payload) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios.put(this.baseUrl + endpoint, payload, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * Delete request
     * @method delete
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    delete(endpoint, payload) {
        this.prepareRequest();
        this.config.data = payload;
        return new Promise((resolve, reject) => {
            axios.delete(this.baseUrl + endpoint, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }


    requestRejected(error) {
        console.log(error.response);
        if (typeof error.response.status !== 'undefined') {

            if (error.response.status === 401) {
                console.log('API Myspace logged out');
                WV.store.dispatch('auth/logout').then(() => {
                    WV.router.push("/");
                })
            }
            if (error.response.status === 500) {
                WV.toast.error(error.response.data.message, 10000);
            }

        }
    }

}

export default class Api extends BaseApi {
    constructor() {
        super();
    }

    getOperatorGroups() {
        return this.get(`operator-groups`);
    }

    getOperatorActivities(date, group_id) {
        return this.get(`activity-report?date=${date}&group_id=${group_id}`);
    }

    getRecommendations() {
        return this.get(`manager/crossale-recommendations`);
    }

    createRecommendation(recommendation) {
        return this.post(`manager/crossale-recommendations`, recommendation);
    }

    updateRecommendation(recommendation) {
        return this.put(`manager/crossale-recommendations?recommendation_id=${recommendation.recommendation_id}`, recommendation);
    }

    deleteRecommendation(recommendation) {
        return this.delete(`manager/crossale-recommendations?recommendation_id=${recommendation.recommendation_id}`, {});
    }

}
